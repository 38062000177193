@import 'assets/styles/_variables';

@import './default/_button';
@import './default/_form';
@import './default/_list';

body {
  background: $cl-grey-bg;
  color: $cl-grey-black;
  font-family: $font-family-roboto;
  font-size: $font-size-normal;
  font-weight: 400;
  line-height: 1.15;

  &.no-scroll {
    overflow: hidden;
  }
}

h1 {
  font-size: 46px;
  line-height: 54px;
  margin: 0;
  padding: 0;
  font-weight: 500;
}

h2 {
  font-size: 26px;
  line-height: 30px;
  margin: 0 0 $spacing;
  padding: 0;
  font-weight: 500;
}

h3 {
  font-size: 18px;
  line-height: 24px;
  margin: 0 0 $spacing;
  padding: 0;
  font-weight: 500;
}