@import 'assets/styles/_variables';

.deck-empty {
  height: 50vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.deck-empty__title {
  color: $cl-grey-medium;
  font-weight: 700;
  font-size: $font-size-xhuge;
  text-align: center;
  margin-bottom: 15px;
}

.deck-empty__subtitle {
  color: $cl-grey-medium;
  font-size: $font-size-normal;
  line-height: 22px;
  text-align: center;
  margin: 0;
}