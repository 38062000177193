@import 'assets/styles/_variables';

.page-header {
  background: $cl-white;
  border-bottom: 1px solid $cl-grey;
  box-shadow: 0px 4px 10px -2px rgba($cl-black, .3);
  position: relative;

  .page-header__inner {
    align-items: flex-end;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0 auto;
    max-width: $container-width;
    padding: 14px $spacing 14px $spacing;
  }

  .page-header__navigation {
    align-items: center;
    display: flex;
    flex-direction: row;
  }

  .page-header__logo {
    display: inline-block;
    height: 42px;
    margin-right: $spacing-huge;
    width: 29px;
    color: $cl-black;

    &--disabled {
      cursor: not-allowed;
      color: $cl-grey-disabled;
    }
  }

  .page-header__links {
    margin-left: 6px;
    width: 100%;
  }

  .page-header__link {
    color: $cl-grey-medium;
    display: inline-block;
    padding-bottom: 6px;
    position: relative;
    text-decoration: none;
    transition: color .2s ease;

    & + .page-header__link {
      margin-left: $spacing-huge;
    }

    @include hover {
      color: $cl-grey-black;
    }

    &--active {
      color: $cl-grey-black;

      &::after {
        background: $cl-purple;
        bottom: -17px;
        border-radius: 3px;
        content: '';
        height: 6px;
        position: absolute;
        left: 0;
        right: 0;
      }
    }

    &--disabled {
      cursor: not-allowed;
      color: $cl-grey-disabled;

      &:hover {
        color: $cl-grey-disabled;
      }
    }
  }

  .page-header__profile {
    padding-bottom: 6px;
  }
}