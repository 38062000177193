@import 'assets/styles/_variables';

.media-card {
  cursor: pointer;
  padding: 0;
  transition: box-shadow .2s ease, transform .4s ease;

  &__content {
    box-sizing: border-box;
    min-height: 88px;
    padding: $spacing 70px $spacing $spacing-large;
  }

  &__title {
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;

    &--url span {
      word-break: break-all;
    }
  }

  &__check {
    align-items: center;
    border: 3px solid $cl-grey-border;
    border-radius: 50%;
    content: '';
    color: $cl-white;
    display: flex;
    height: 31px;
    justify-content: center;
    left: $spacing-small;
    position: absolute;
    top: $spacing-small;
    transition: .2s ease;
    transition-property: background, border, margin;
    width: 31px;
    z-index: 1;

    .icon {
      opacity: 0;
      transition: .2s ease;
      transition-property: color, opacity;
      color: $cl-grey-border;
    }
  }

  @include hover {
    transform: scale(1.08);

    .media-card__check {
      .icon {
        opacity: 1;
      }
    }
  }
  
  &--with-image {
    .media-card__check {
      border-color: $cl-white;
      
      .icon {
        color: $cl-white;
        opacity: 0;
      }
    }
  }

  &--selected {
    box-shadow: 0 0 0 2px $cl-green;

    @include hover {
      box-shadow: 0 0 0 2px $cl-green;
      transform: scale(1);
    }

    .media-card__check {
      background-color: $cl-green;
      border-color: $cl-green;

      .icon {
        opacity: 1;
        color: $cl-white;
      }
    }
  }
}