@import 'assets/styles/_variables';

.icon {
  display: inline-block;
}

.icon--block {
  img, svg {
    display: block;
  }
}

.icon--small {
  width: 16px;
  height: 16px;
}

.icon--medium {
  width: 24px;
  height: 24px;
}

.icon--large {
  width: 40px;
  height: 40px;
}
