@import 'assets/styles/_variables';

.pool-navigation {
  background: $cl-grey-bg;
  position: sticky;
  margin-top: $spacing;
  padding-top: 2px;
  top: -2px;
  z-index: 10;
}

.pool-navigation__links {
  display: flex;
  line-height: 24px;
  margin: 0;
  padding: $spacing 0;

  li {
    list-style: none;
  }
}

.pool-navigation__title {
  display: none;
  font-weight: bold;
  font-size: 20px;
  line-height: 22px;
  margin-bottom: -4px;
  margin-right: $spacing-huge;
}

.pool-navigation__link {
  color: $cl-grey-dark;
  margin-right: $spacing-huge;
  position: relative;
  text-decoration: none;

  &--active {
    color: $cl-grey-black;

    &::after {
      background: $cl-purple;
      border-radius: 3px;
      bottom: -26px;
      content: '';
      height: 6px;
      left: 0;
      position: absolute;
      right: 0;
    }
  }

  &--disabled {
    cursor: not-allowed;
    color: $cl-grey-disabled;
  }
}

.pool-navigation--stuck {
  border-bottom: 1px solid $cl-grey;

  .pool-navigation__title {
    display: block;
  }
}