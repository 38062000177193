@import 'assets/styles/_variables';

.login-page {
  min-height: 615px;
}

.login-page__fields-container {
  margin-top: 80px;
  background-color: $cl-white;
  border-radius: 6px;
  box-shadow: 0px 6px 10px -2px rgba($cl-black, .3);
}

.login-page__field:nth-child(n+2) {
  border-top: 1px solid $cl-grey-bg;
}

.login-page__login-button {
  margin-top: 20px;
}

.login-page__lost-credentials-link {
  margin-top: 50px;
  text-align: center;
}