@import 'assets/styles/_variables';

.arrangement-layout {
  color: $cl-grey-border;
  display: block;
  max-width: 100%;
  $border-width: 8px;
  height: 140px;

  &__text {
    color: $cl-black;
  }

  &--rect {
    fill: transparent;
    stroke: currentColor;
    stroke-width: $border-width;
    transition: color .2s linear, outline-color .2s linear;
  }

  &--background {
    fill: transparent;
  }

  &--bottom-margin {
    margin-bottom: 20px;
  }

  .arrangement-layout__border {
    stroke: currentColor;
    stroke-width: $border-width;
  }

  &--active {
    color: lightblue;
  }
}