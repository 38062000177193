@import 'assets/styles/_variables';

.text--small {
  font-size: 14px;
}

.text--color-grey-dark {
  color: $cl-grey-dark
}

.text--flatten {
  margin-bottom: 0;
}