@import 'assets/styles/_variables';

.media-preview {
  &__image {
    margin-left: -80px;
    margin-right: -80px;

    img {
      display: block;
      max-width: 100%;
    }

    video {
      width: 100%;
    }
  }

  &__info {
    margin-top: $spacing-huge;

    &__title {
      font-size: $font-size-huge;
      margin: 0 0 $spacing 0;
    }

    &__list {
      strong {
        padding-right: $spacing-tiny;
      }
    }
  }
}