@import 'assets/styles/_variables';

.arrangement-media {
  &__block {
    margin-bottom: $spacing;

    &--disabled {
      opacity: .4;
      pointer-events: none;
    }
  }

  &__title {
    margin-top: 0;
  }

  &__order-item  {
    background: $cl-white;
    border: 2px solid $cl-grey-border;
    border-radius: 6px;
    padding: $spacing-small 10px;
    position: relative;
    margin-bottom: 6px;
    transition: .2s ease;
    transition-property: box-shadow, top, transition;
    word-break: break-word;

    &--added {
      animation: drop-shadow .2s ease 1 2.5s forwards;
      box-shadow: 0px 4px 10px -2px rgba($cl-black, .6);
      top: -4px;
      transform: scale(1.02);
    }

    &--disabled {
      border: 2px solid $cl-grey-disabled;
      color: $cl-grey-disabled;
    }

    &--changed {
      border: 2px solid $cl-orange;
      color: $cl-grey-disabled;
    }

    &--deleted {
      border: 2px solid $cl-red;
      color: $cl-grey-disabled;
    }
  }

  &__delete {
    position: absolute;
    right: $spacing-small;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
  }

  .button {
    padding-right: 0;
  }
}