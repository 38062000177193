@import 'assets/styles/_variables';


.form__dropdown {
  width: 100%;

  & &__control {
    background: $cl-white;
    border: 0;
    border-radius: 6px;
    box-shadow: 0px 4px 10px -2px rgba($cl-black, .3);
    height: $spacing-huge;
    padding: 0 0 0 $spacing-small;

    @include hover {
      box-shadow: 0px 4px 10px -2px rgba($cl-black, .6);
    }

    &--menu-is-open {
      border-radius: 6px 6px 0 0;
      box-shadow: 0px 6px 10px -2px rgba($cl-black, .6);

      @include hover {
        box-shadow: 0px 6px 10px -2px rgba($cl-black, .6);
      }
    }
  }

  &__label {
    display: inline-block;
  }

  &__icon {
    color: $cl-blue;

    &--disabled {
      color: $cl-grey-disabled;
    }
  }

  &__container {
    display: inline-block;
    position: relative;
    width: 100%;

    .icon {
      pointer-events: none;
      position: absolute;
      right: $spacing-tiny;;
      top: $spacing;
    }

    &--disabled {
      cursor: not-allowed;
    }
  }

  & &__control {
    position: relative;
  }

  & &__value-container {
    padding: 0;
  }

  & &__menu {
    border: 0;
    box-sizing: border-box;
    border-top: 2px solid $cl-grey-bg;
    border-radius: 0 0 6px 6px;
    box-shadow: 0px 6px 10px -2px rgba($cl-black, .6);
    margin: 0;
    z-index: 10;
  }

  & &__menu-list {
    padding: $spacing-tiny 0;
  }

  & &__option {
    padding: 10px $spacing-small;
    cursor: pointer;

    &--is-focused,
    &--is-selected {
      background: none;
      color: $cl-blue;
    }

    @include hover {
      background: none;
      color: $cl-blue;
    }
  }

  &__multi-value {
    &--is-disabled {
      &:before {
        color: $cl-grey-disabled;
      }

      .form__dropdown__multi-value__label {
        color: $cl-grey-disabled;
      }
    }
  }

  &--changed {
    .form__dropdown__control {
      border: 2px solid $cl-orange;

      @include hover {
        border: 2px solid $cl-orange;
      }
    }
  }
}
