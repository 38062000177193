@import 'assets/styles/_variables';

.infobar {
  background: $cl-grey-black;
  border-radius: 10px 10px 0 0;
  bottom: 0;
  color: $cl-white;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 780px;
  left: 0;
  padding: 12px $spacing;
  position: fixed;
  right: 0;
  width: 100%;
  z-index: 80;

  &__icon {
    align-items: center;
    background: $cl-green;
    border-radius: 50%;
    display: flex;
    height: 35px;
    justify-content: center;
    margin-right: $spacing-small;
    min-width: 35px;

    &--error {
      background: $cl-red;
    }

    &--warning {
      background: $cl-orange;
    }
  }

  &__text {
    align-self: center;
    width: 100%;
  }

  &__action {
    align-self: center;
    padding: 0;
    min-height: auto;
    min-width: 100px;

    + .infobar__action {
      margin-left: 10px;
    }

    &--delete,
    &--approval {
      box-shadow:
        0px 4px 10px -2px rgba(0, 0, 0, 0.5),
        0px 8px 25px -4px rgba(0, 26, 38, 0.35);
      height: 30px;
    }
  }
}