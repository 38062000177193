@import 'assets/styles/_variables';

.form__label {
  display: block;
  padding-bottom: $spacing-tiny;

  .icon {
    color: $cl-blue;
    margin-bottom: -1px;
    margin-right: 6px;
  }
}