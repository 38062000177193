@import 'assets/styles/_variables';

.calendar {

  .fc-button {
    &-active {
      display: none;
    }

    .fc-icon {
      display: none;
    }
  }

  .fc-button-primary {
    background: none;
    border: none;
    color: $cl-purple;
    font-weight: 500;

    &:focus {
      &, &:active {
        background: none;
        color: $cl-purple;
        box-shadow: none;

      }
    }
  }

  // button specifically for 'today'
  .fc-button.fc-today-button {
    color: $cl-black;
    font-weight: normal;

    &:active, &:focus {
      color: $cl-black;
    }
  }

  .fc-prev-button {
    &::before {
      background-image: url('/icons/icon_arrow-left.svg');
      content: '';
      display: block;
      height: 14px;
      width: 18px;
    }
  }

  .fc-next-button {
    &::before {
      background-image: url('/icons/icon_arrow-right.svg');
      content: '';
      display: block;
      height: 14px;
      width: 18px;
    }
  }

  // toolbar above table
  .fc-toolbar {

    // title in the toolbar
    h2 {
      font-size: $font-size-huge;
      line-height: 30px;
    }
  }

  // row of the header
  .fc-head-container {
    border-bottom: 1px solid $cl-grey-dark;
  }

  // cell in header for date
  .fc-day-header {
    color: $cl-grey-dark;
    font-weight: normal;
    font-size: $font-size-normal;
    padding: 10px 0 20px 5px;
    text-align: left;
  }

  // body of the table
  .fc-body {
    border: 2px solid $cl-grey-border;
  }

  // body of rows
  .fc-slats {
    td {
      height: 60px;
    }
  }

  .fc-axis {
    border-right: 0;
  }

  .fc-widget-header {
    border: 0;
  }

  // column for today
  td.fc-today {
    background: none;
  }

  // first background column
  .fc-mon {
    border-left: 0;
  }

  // each half hour row (6:30, 7:30, etc)
  .fc-minor {
    border-bottom: 1px solid $cl-grey;
  }

  // content cell
  .fc-widget-content {
    border-top: 0; // border top was hiding the fc-minor border bottom
    border-left: 0;
    background: none;
  }

  // time cell
  .fc-time {
    background: $cl-grey-bg;
    font-size: $font-size-small;
    text-align: center;
    transform: translateY(-10px);
    vertical-align: top;
  }

  // horizontal line for the current time
  .fc-now-indicator {
    border-color: $cl-purple;
    border-top-width: 2px;

    // arrow aligned with the line
    &-arrow {
      height: 2px;
      border-width: 0px !important;
      margin: 0;
      z-index: 10;

      &::before {
        background: $cl-grey-bg;
        box-shadow: 0px 0px 4px $cl-grey-light;
        content: var(--time);
        color: $cl-purple;
        display: block;
        font-size: $font-size-small;
        height: 14px;
        transform: translateY(-50%);
        pointer-events: none;
        text-align: center;
        width: 43px;
      }
    }
  }

  // parent of now indicator
  .fc-content-col {
    position: unset; // relative position kept the now indicator in one day
  }

  // event card
  .fc-time-grid-event {
    background: $cl-white;
    border: 0px solid $cl-white;
    border-radius: 4px;
    box-shadow: inset 2px 0px 0px $cl-blue, 0px 2px 6px 0px rgba($cl-black, .3);
    color: $cl-grey-black;
    font-size: $font-size-small;
    padding: 10px;

    // !important to overwrite the inline style on each event
    left: 0 !important;
    right: 0 !important;

    // overlapping events
    &.fc-time-grid-event-inset {
      left: 4px !important;
    }

    // time label inside an event card
    .fc-time {
      display: none;
      transform: none;
    }
  }

  // actual event
  .calendar__event {
    cursor: pointer;
    margin: 3px 0;

    &--inactive {
      box-shadow:
        inset 0px 0px 0px 2px $cl-grey-disabled,
        inset 4px 0px 0px 0px $cl-grey-disabled,
        0px 2px 6px 0px rgba($cl-black, 0.3);;
    }
  }

  // actual event in the past
  .calendar__event--past {
    opacity: .5;
  }

  .calendar__event--pending {
    box-shadow: inset 2px 0px 0px $cl-orange, 0px 2px 6px 0px rgba($cl-black, .3);
    border: 2px solid $cl-orange;
  }

  // make the current time bigger to hide the timestamp
  &--overlapping-time {
    .fc-now-indicator-arrow {
      &::before {
        align-items: center;
        display: flex;
        height: 60px;
        justify-content: center;
      }
    }
  }
}