@import '_mixins';

$font-family-roboto: 'Roboto', sans-serif;

$container-width: 1024px;

$cl-white: #FFFFFF;
$cl-grey-light: #F8F9FB;
$cl-grey-bg: #EDEEF0;
$cl-grey: #E4E5E6;
$cl-grey-border: #CBD5D6;
$cl-grey-medium: #7D8080;
$cl-grey-dark: #646666;
$cl-grey-black: #323333;
$cl-grey-disabled: #AFB3B3;
$cl-black: #000000;
$cl-purple: #812990;
$cl-red: #D0021B;
$cl-orange: #FF901B;
$cl-blue: #0095DA;
$cl-blue-gradient: #0087C9;
$cl-green: #7ED321;

$font-size-small: 14px;
$font-size-normal: 16px;
$font-size-large: 19px;
$font-size-xlarge: 22px;
$font-size-huge: 26px;
$font-size-xhuge: 34px;

$spacing-tiny: 8px;
$spacing-small: 16px;
$spacing: 20px;
$spacing-medium: 25px;
$spacing-large: 30px;
$spacing-huge: 50px;
