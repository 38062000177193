ul, ol {
  &.list--reset {
    list-style: none;
    margin: $spacing 0;
    padding: 0;
    line-height: 22px;

    li {
      margin-bottom: $spacing-tiny;
    }
  }
}