@import 'assets/styles/_variables';

$button-min-size: 68px;
$button-min-size-small: 36px;
$button-interaction: '.button__interaction';

.button {
  background: linear-gradient(90deg, $cl-blue, $cl-blue-gradient);
  border-radius: 35px;
  box-sizing: border-box;
  box-shadow: 0px 4px 10px -2px rgba($cl-black, .5);
  color: $cl-white;
  cursor: pointer;
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  line-height: 28px;
  min-height: $button-min-size;
  min-width: $button-min-size;
  outline: none;
  padding: 20px;
  text-align: center;
  text-decoration: none;
  transition: .2s ease;
  transition-property: background, box-shadow, color;
  white-space: nowrap;
  -webkit-appearance: none !important;

  @include hover($button-interaction) {
    box-shadow: 0px 6px 12px -2px rgba($cl-black, .5);

    .button__hover-text {
      max-width: 400px;
      transition-delay: 0s;
    }

    .button__hover-spacing {
      transition-delay: 0s;
      width: 20px;
    }
  }

  &:active {
    box-shadow: 0px 4px 10px -2px rgba($cl-black, .5);
  }

  &:disabled {
    background: $cl-grey-disabled;
    box-shadow: none;
    cursor: not-allowed;
  }
}

// Button elements

.button__text {
  align-items: center;
  align-self: center;
  display: flex;
  min-width: 28px;
}

.button__hover-text {
  display: inline-block;
  max-width: 0;
  overflow: hidden;
  transition: max-width .3s ease;
  transition-delay: .2s;
  text-align: right;
}

.button__hover-spacing {
  display: inline-block;
  width: 0px;
  transition: width .1s ease;
  transition-delay: .4s;
}

// Button sizes

.button--small {
  padding: 4px 8px;
  min-height: $button-min-size-small;
  min-width: $button-min-size-small;

  .button__text {
    min-width: 16px;
  }
}

.button--inline {
  padding: 0;
  min-height: 0;
  min-width: 0;

  .button__text {
    min-width: 0;
  }
}

.button--medium {
  border-radius: 6px;
  min-height: 50px;
  padding-bottom: 0;
  padding-top: 0;
}

.button--large {
  border-radius: 6px;
  min-height: 60px;
  padding-bottom: 0;
  padding-top: 0;
  font-size: $font-size-large;
}

// Button additional styles

.button--no-shadow {
  box-shadow: none;

  @include hover($button-interaction) {
    box-shadow: none;
  }
}

.button--text-inverse {
  flex-direction: row-reverse;
}

.button--block {
  width: 100%;
}

// Button schemes

.button--scheme-card {
  background: $cl-grey-border;
  color: $cl-grey-black;

  @include hover($button-interaction) {
    background: $cl-purple;
    color: $cl-white;
  }
}

.button--scheme-link {
  background: none;
  padding-left: 0;
  min-width: auto;
  color: $cl-blue;

  @include hover($button-interaction) {
    text-decoration: underline;
  }
}

.button--scheme-link--disabled {
  color: $cl-grey-disabled;
  cursor: not-allowed;
}

.button--color-red {
  background: $cl-red;
}
.button--color-green {
  background: $cl-green;
}