@import 'assets/styles/_variables';

.pool-filter {
  &__bar {
    display: flex;
    margin-top: #{-$spacing-tiny};
    width: 100%
  }

  &__input {
    margin-right: 70px;
    width: 100%;
  }

  &__options {
    align-items: flex-end;
    display: flex;
    justify-content: flex-end;
    min-width: 260px;
    text-align: right;
  }

  &__advanced {
    margin-top: $spacing-huge;
  }
}