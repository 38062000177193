fieldset {
  border: 0;
  padding: 0;
  margin: 0 0 $spacing 0;

  legend {
    font-size: $font-size-huge;
    font-weight: 500;
    margin-bottom: $spacing;
  }
}

.form {
  display: flex;
  flex-direction: column;
  min-height: 100%;

  &__information {
    margin-bottom: $spacing-huge;

    &__detail {
      opacity: .5;
    }

    .status {
      margin-top: $spacing;
    }
  }

  &__text {
    color: rgba($cl-grey-black, .5);
    line-height: 22px;
    margin-top: 0;
  }

  &__error {
    color: $cl-red;
    margin-top: $spacing-tiny;
    margin-bottom: 0;
    padding: 0 $spacing-small;

    &--wide {
      padding: 0;
    }
  }

  &__legend--spacing {
    margin-bottom: $spacing-large;
    padding-top: $spacing;
  }

  &__container-error {
    border-radius: 0 1px 1px 0;
    bottom: 12px;
    background: $cl-red;
    left: 0;
    position: absolute;
    top: 12px;
    width: 2px;
  }
}
