@import 'assets/styles/_variables';

.status {
  color: $cl-grey-medium;
  font-size: $font-size-small;
  line-height: $font-size-normal;

  &__light {
    background: $cl-grey;
    border-radius: 50%;
    display: inline-block;
    height: 15px;
    margin-right: 10px;
    position: relative;
    top: 2px;
    width: 15px;

    &--active {
      background: $cl-green;
    }

    &--pending {
      background: $cl-orange;
    }
  }
}