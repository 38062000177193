@import 'assets/styles/_variables';

.trigger-card {
  padding: $spacing-large;

  &__title {
    line-height: 28px;
    margin: 0 0 $spacing-large;
    min-height: 56px;
  }

  &__list {
    margin-bottom: $spacing;
    position: relative;

    &::before {
      border-left: 2px dashed $cl-grey;
      bottom: 3px;
      content: '';
      left: 5px;
      position: absolute;
      top: 0;
      width: 2px;
    }
  }

  &__list-item {
    position: relative;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    .icon {
      background: $cl-white;
      color: $cl-blue;
      margin-bottom: -1px;
      margin-right: 10px;
      width: 14px;
    }

    & + & {
      padding-top: $spacing;
    }
  }

  .card__button {
    bottom: $spacing-large;
    right: $spacing-large;
  }
}