@import 'assets/styles/_variables';

.media-page {
  .detail-button {
    bottom: 50px;
    position: fixed;
    right: 50px;
    z-index: 50;
  }

  &__filter {
    background: $cl-grey;
    padding: $spacing-huge 0 $spacing-large;
  }

  &__content {
    padding-bottom: $spacing-huge;
    padding-top: $spacing-huge;
  }
}
