@import 'assets/styles/_variables';

.folder-dropdown {
  position: relative;

  &--open {
    .folder-dropdown__selected {
      border-radius: 6px 6px 0 0 ;
    }
  }

  &__selected {
    align-items: center;
    background: $cl-white;
    border-radius: 6px;
    box-shadow: 0px 6px 10px -2px rgba($cl-black, .3);
    display: flex;
    justify-content: space-between;
    line-height: $spacing-huge;
    outline: 0;
    padding: 0 $spacing-small;
    transition: box-shadow .2s ease;

    @include hover {
      box-shadow: 0px 6px 10px -2px rgba($cl-black, .6);
    }

    &__toggle {
      color: $cl-blue;
    }
  }

  &__selection {
    background: $cl-white;
    border-top: 2px solid $cl-grey-bg;
    border-radius: 0 0 6px 6px;
    box-shadow: 0px 6px 8px -2px rgba($cl-black, .6);
    box-sizing: border-box;
    max-height: 300px;
    padding: $spacing-tiny $spacing-small;
    overflow-y: auto;
  }

  &__item {
    cursor: pointer;
    line-height: 28px;

    .folder-dropdown__item {
      padding-left: 30px;
    }

    &__info {
      display: flex;

      @include hover {
        color: $cl-blue;
      }
    }

    &__name {
      color: $cl-black;
      display: inline-block;
      width: 100%;

      @include hover {
        color: $cl-blue;
        text-decoration: none;
      }
    }

    &__toggle {
      box-sizing: border-box;
      min-height: 0;
      padding: 4px 4px 4px 0;
      text-align: center;
      user-select: none;
      width: 20px;

      @include hover {
        text-decoration: none;
      }
    }

    &--disabled {
      cursor: auto;

      .folder-dropdown__item__toggle {
        color: $cl-grey-disabled;
        cursor: pointer;
      }

      .folder-dropdown__item__name {
        color: $cl-grey-disabled;
        pointer-events: none;
      }
    }
  }
}