@import 'assets/styles/_variables';

.form__datetime {
  background: $cl-white;
  border: none;
  border-radius: 4px;
  box-shadow: 0px 4px 10px -2px rgba($cl-black, .3);
  box-sizing: border-box;
  color: $cl-black;
  height: 50px;
  outline: none;
  padding: 0 16px;
  transition: box-shadow .2 ease;
  width: 150px;

  &:not([disabled]) {
  @include hover {
    box-shadow: 0px 4px 10px -2px rgba($cl-black, .6);
  }
}

  &--time {
    width: 100px;
  }

  &__container {
    display: inline-block;
    position: relative;

    .icon {
      align-items: center;
      display: inline-flex;
      height: 50px;
      pointer-events: none;
      position: absolute;
      right: 10px;
      top: 0;
    }
  }

  &--disabled {
    color: $cl-grey-disabled;
    border-color: $cl-grey-disabled;
    cursor: not-allowed;
  }

  &--changed {
    border: 2px solid $cl-orange;
    line-height: 46px;
  }

  &__icon {
    color: $cl-blue;

    &--disabled {
      color: $cl-grey-disabled;
    }
  }
}

// datepicker component
.react-datepicker-popper {
  z-index: 2;
}