@import 'assets/styles/_variables';

.form__checkbox {
  cursor: pointer;
  line-height: 20px;

  &__check {
    border: 2px solid $cl-grey-border;
    border-radius: 4px;
    box-sizing: border-box;
    display: inline-block;
    height: 20px;
    margin: 0 10px -4px 0;
    width: 20px;

    &::before {
      background: transparent;
      border-radius: 2px;
      content: '';
      display: block;
      height: 12px;
      margin: 2px;
      transition: background .2s ease;
      width: 12px;
    }
  }

  &__label {
    color: $cl-blue;
    display: inline-block;
  }

  @include hover {
    .form__checkbox__check {
      &::before {
        background-color: $cl-grey-border;
      }
    }
  }

  input {
    display: none;

    &:checked + .form__checkbox__check {
      &::before {
        background-color: $cl-blue;
      }
    }
  }

  &--disabled {
    cursor: not-allowed;

    @include hover {
      .form__checkbox__check {
        &::before {
          background-color: $cl-white;
        }
      }
    }

    input:checked + .form__checkbox__check::before
    {
      background-color: $cl-grey-border;
    }

    .form__checkbox__label {
      color: $cl-grey-disabled;
    }
  }
}