@import 'assets/styles/_variables';

.folder-header {
  display: flex;
}

.folder-header__folder-name {
  margin-right: 15px;
}

.folder-header__folder-button {
  font-size: 16px;
  margin-bottom: 15px;
  color: $cl-blue;
}