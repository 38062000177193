@import 'assets/styles/_variables';

.popup__background {
  background: rgba($cl-grey-black, .75);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 60;
}

.popup__content {
  background: $cl-grey-light;
  border-radius: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 160px);
  max-width: 780px;
  overflow-y: visible;
  position: fixed;
  width: 100%;
  z-index: 70;
}

.popup__wrapper {
  overflow-y: auto;
  padding: $spacing-huge 80px;
}

.popup__content--position-top {
  left: 0;
  right: 0;
  top: 0;
  margin: 80px auto;
}

.popup__content--position-center {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}